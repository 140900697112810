import { createEffect } from 'effector';

import { PortalAuthService } from 'apps/portal/services';

/**
 * Get the currently logged-in user
 */
export const getUser = createEffect(PortalAuthService.getUser);

/**
 * Handle login for email links with auth token
 */
export const login = createEffect(PortalAuthService.login);

/**
 * Request email to log into enterprise account.
 * If email is not associated with an enterprise,
 * an informative error will say so.
 */
export const requestLoginLink = createEffect(PortalAuthService.requestLoginLink);

/**
 * Logout of current session
 */
export const logout = createEffect(PortalAuthService.logout);
