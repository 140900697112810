import React from 'react';

import Providers from '@m/providers';

import Portal from './Portal';

export default function App() {
  return (
    <Providers>
      <Portal />
    </Providers>
  );
}
