import styled, { createGlobalStyle } from 'styled-components';

export const AntdOverrides = createGlobalStyle`
  :root,
  body {
    /* TODO: themev2 color */
    background-color: rgb(246,246,254) !important;
  }

  /* Override Ant Design: */
  #root {
    font-family: 'Neue Montreal', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #f6f6ff !important;
    }

    .ant-select-item {
      user-select: none;
    }

    .ant-btn {
      font-weight: 500 !important;
    }

    .ant-btn.ant-btn-primary {
      font-family: 'Neue Montreal', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif !important;
      border: 1px solid rgba(0, 0, 0, 0.225) !important;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25) !important;
      background-color: #5f59ff !important;
      color: #fff !important;
      border-radius: 5px !important;
      letter-spacing: 0.02em !important;
      -webkit-font-smoothing: antialiased !important;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.225);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        background-color: #5f59ff !important;
        border-radius: 5px;
      }

      &:hover {
        background-color: #5f59ff !important;
        background-image: linear-gradient(
          rgba(255, 255, 255, 0.06),
          rgba(255, 255, 255, 0.06)
        );
        border: 1px solid rgba(0, 0, 0, 0.225);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      }

      &:active {
        background-color: #5f59ff !important;
        border: 1px solid rgba(0, 0, 0, 0.225);
        background-image: linear-gradient(rgba(0, 0, 0, 0.225), rgba(0, 0, 0, 0.225));
        box-shadow: none;
        color: rgba(255, 255, 255, 0.7);
        transition: none;
      }

      &:disabled {
        color: #fff !important;
        opacity: 0.35 !important;
        background-image: none !important;
        border: 1px solid #5f59ff !important;
        box-shadow: none !important;
        filter: grayscale(0.25);
      }

      &.ant-btn-background-ghost {
        border: 1px solid #5f59ff;
        box-shadow: none;
        color: #5f59ff;

        &:hover {
          background-color: #f6f6ff !important;
        }

        &:active {
          background-color: #e2dfff !important;
        }
      }
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    font-family: inherit;
  }
`;

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    /* TODO: themev2 color */
    background-color: rgb(246,246,254);
    color: ${({ theme }) => theme.colors.grey7};
    box-sizing: border-box;
  }

  html > * {
    z-index: 1;
  }

  #plaid-link-temporary-id::before {
    pointer-events: none !important;
    opacity: 0;
  }

  #root {
    position: relative;
    z-index: 1;
  }

  *,
  *::before,
  *::after {
    color: inherit;
    box-sizing: inherit;
    font-family: 'Neue Montreal', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const AppBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
`;

export const ContentBox = styled.div<{ authenticated: boolean | null; removePadding: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 200px;

  @media (min-width: 580px) {
    padding-left: ${({ authenticated, removePadding }) =>
      authenticated && !removePadding ? 250 : 0}px;
  }
`;

export const AlertBox = styled.div`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;
